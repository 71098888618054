var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
        [
          _c(
            "c-table",
            {
              ref: "mstTable",
              attrs: {
                title: "메시지 타입",
                tableId: "mstTable",
                columnSetting: false,
                isFullScreen: false,
                filtering: false,
                usePaging: false,
                hideBottom: true,
                columns: _vm.mstGrid.columns,
                data: _vm.mstGrid.data,
                selection: "single",
                rowKey: "mstCd",
                isExcelDown: false,
              },
              on: { rowClick: _vm.getDtls },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "", icon: "add" },
                            on: { btnClicked: _vm.addRowMst },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "", icon: "save" },
                            on: { btnClicked: _vm.saveMst },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "", icon: "remove" },
                            on: { btnClicked: _vm.removeMst },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "q-dialog",
            {
              attrs: { persistent: "" },
              model: {
                value: _vm.prompt,
                callback: function ($$v) {
                  _vm.prompt = $$v
                },
                expression: "prompt",
              },
            },
            [
              _c(
                "q-card",
                { staticStyle: { "min-width": "350px" } },
                [
                  _c("q-card-section", [
                    _c("div", { staticClass: "text-h6" }, [
                      _vm._v("메시지타입 코드"),
                    ]),
                  ]),
                  _c(
                    "q-card-section",
                    { staticClass: "q-pt-none" },
                    [
                      _c("q-input", {
                        attrs: { dense: "", autofocus: "" },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.setMst.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.hiddenMstCd,
                          callback: function ($$v) {
                            _vm.hiddenMstCd = $$v
                          },
                          expression: "hiddenMstCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "q-card-actions",
                    { staticClass: "text-primary", attrs: { align: "right" } },
                    [
                      _c("q-btn", {
                        attrs: { flat: "", label: "취소" },
                        on: { click: _vm.cancelMst },
                      }),
                      _c("q-btn", {
                        attrs: { flat: "", label: "추가" },
                        on: { click: _vm.setMst },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
        [
          _c("c-table", {
            ref: "dtlGrid",
            attrs: {
              title: "메시지 목록",
              tableId: "dtlGrid",
              columnSetting: false,
              isFullScreen: false,
              columns: _vm.dtlGrid.columns,
              data: _vm.dtlGrid.data,
              isExcelDown: false,
            },
            on: { rowClick: _vm.rowClick },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "상세" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && _vm.isMstSelected
                            ? _c("c-btn", {
                                attrs: { label: "신규", icon: "add" },
                                on: { btnClicked: _vm.addDtl },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.saveable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.dtlSaveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.saveType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveDtl,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.deleteable
                            ? _c("c-btn", {
                                attrs: { label: "삭제", icon: "remove" },
                                on: { btnClicked: _vm.deleteDtl },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: false,
                            label: "메시지코드",
                            name: "msgCd",
                          },
                          model: {
                            value: _vm.data.msgCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "msgCd", $$v)
                            },
                            expression: "data.msgCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            required: true,
                            label: "메시지",
                            name: "msgVal",
                          },
                          model: {
                            value: _vm.data.msgVal,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "msgVal", $$v)
                            },
                            expression: "data.msgVal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            label: "메시지-영문",
                            name: "msgValEn",
                          },
                          model: {
                            value: _vm.data.msgValEn,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "msgValEn", $$v)
                            },
                            expression: "data.msgValEn",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            label: "메시지-일본어",
                            name: "msgValJa",
                          },
                          model: {
                            value: _vm.data.msgValJa,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "msgValJa", $$v)
                            },
                            expression: "data.msgValJa",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            label: "메시지-중국어간체",
                            name: "msgValZhCn",
                          },
                          model: {
                            value: _vm.data.msgValZhCn,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "msgValZhCn", $$v)
                            },
                            expression: "data.msgValZhCn",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable && _vm.dataeditable,
                            label: "메시지-중국어번체",
                            name: "msgValZhTw",
                          },
                          model: {
                            value: _vm.data.msgValZhTw,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "msgValZhTw", $$v)
                            },
                            expression: "data.msgValZhTw",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }